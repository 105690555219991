<template>
	<div class="content_tit_wrap">
		<div class="shortline"></div>
		<div class="tit_line" @click="visible = !visible">
			<h2>댓글</h2>
			<div class="content_more">
				<img v-if="visible === false" alt="more" src="@/assets/images/icon_more.png" />
				<img v-else alt="more" src="@/assets/images/menu_close.png" />
			</div>
		</div>
	</div>
	<div class="textarea_wrap" v-show="visible">
		<div class="nun_characters">{{ comment.length }}/500</div>
		<div class="textarea_area">
			<textarea id name v-model="comment" cols="33" placeholder class="mgb0" maxlength="500"></textarea>
			<button @click="insertComment" type="button" class="btn_basic btn_green_bg">등록</button>
		</div>
	</div>

	<div class="comment_wrap" v-for="(item, index) in commentList" :key="index" v-show="visible">
		<div class="comment_info_wrap">
			<ul>
				<li class="comment_id">{{ item.nickname }}</li>
				<li class="comment_date">{{ getReplyRegistTime(item.reg_date) }}</li>
			</ul>
		</div>
		<div class="comment">
			<p>{{ item.reply_content }}</p>
		</div>
		<span v-if="checkSameUser(item)" @click="deleteComment(item)" class="del_btn">삭제</span>
	</div>

	<Pagination @changePage="changePage" :page="page" :totalPage="totalPage" v-show="visible"></Pagination>
</template>

<script setup>
import { contentCommentStore, contentModalStore } from '@/store/contentDetailStore';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import Pagination from '@/shared-components/Pagination.vue';
import moment from 'moment';

const props = defineProps({
	commentList: Object,
	loginState: Boolean,
	loginInfo: Object,
});

// store setting
const store = contentCommentStore();
const { page, totalPage, totalItemCount } = storeToRefs(store);

// 페이지 변경 시
const changePage = (page) => {
	store.setPage(page);
};

// 입력값 인스턴트
const comment = ref('');

// 댓글 등록 날짜 포맷 변환
const getReplyRegistTime = (time) => {
	const date = moment(time);
	return date.format('YYYY. MM. DD');
};

// content store setting
const commentStore = contentCommentStore();

// modal store setting
const modalStore = contentModalStore();

// 댓글 등록 로직
const insertComment = () => {
	if (comment.value.length === 0) {
		alert('최소 한 글자 이상 입력해주세요');
		return false;
	}
	// 등록 로직 진행
	if (props.loginState) {
		store.insertComment(comment.value);
	} else {
		modalStore.setModalActive(true);
		modalStore.setModalType('login');
	}

	// 입력값 초기화
	comment.value = '';
};

// 댓글 작성자 본인인지 확인
const checkSameUser = (item) => {
	if (props.loginInfo) {
		if (item.mem_no == props.loginInfo.userNo) {
			return true;
		} else {
			return false;
		}
	}
};

// 컨텐츠 출력 여부
const visible = ref(true);

// 댓글 삭제 함수
const deleteComment = (comment) => {
	if (confirm('해당 댓글을 삭제하시겠습니까?')) {
		commentStore.deleteComment(comment);
	} else {
		return;
	}
};
</script>

<style scoped>
.comment_wrap {
	position: relative;
}
.del_btn {
	position: absolute;
	top: 20px;
	right: 15px;
	font-size: 13px;
	cursor: pointer;
}

.del_btn:hover {
	text-decoration: underline;
}
</style>
