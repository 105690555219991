<template>
	<div class="modal_head">
		<h2>수정 완료</h2>
		<i class="bx bx-x" @click="offModal"></i>
	</div>
	<div class="modal_txt">수정 완료 되었습니다.</div>
</template>

<script setup>
import { contentModalStore } from '@/store/contentDetailStore';

const store = contentModalStore();
const offModal = () => {
	store.setModalActive(false);
	store.setModalType(null);
};
</script>

<style></style>
