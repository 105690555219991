<template>
	<div class="detail_page_top_wrap" v-if="contentInfo">
		<div class="detail_titimg_warap">
			<img alt="thumbnail" :src="`${$image_host}/contents/${contentInfo.thumb_path}`" />
			<div v-if="contentInfo.evt_yn === 'Y'" class="sticker">이벤트중</div>
		</div>
		<div class="detail_txtbox_wrap">
			<div class="detail_top_tit_wrap">
				<dl>
					<dt>{{ $convertGenreCode(contentInfo.genre) }}</dt>
					<dd>{{ contentInfo.opus_title }}</dd>
				</dl>
				<ul>
					<li>{{ episodeStore.getEpisodesDuration }}</li>
					<li>
						<div><i class="bx bx-play play"></i></div>
						{{ contentInfo.view_cnt.toLocaleString() }} 청취
					</li>
					<li>
						<div><i class="bx bxs-star grade"></i></div>
						{{ contentInfo.star_score }}/10
					</li>
				</ul>
			</div>
			<div class="detail_mid_wrap">
				<div class="detail_tag_wrap">
					<ul>
						<li v-for="(item, index) in contentInfo.hash_tag.split(',')" :key="index">{{ item }}</li>
					</ul>
				</div>
				<p>
					{{ contentInfo.story_intro }}
				</p>
			</div>
			<div class="detail_btm_wrap">
				<div class="datail_btn_wrap">
					<button @click="activePlayer" class="btn_basic btn_green_bg">
						<span>재생</span>
					</button>
					<!-- <button @click="activeFirstEpi" class="btn_basic btn_green_bg">
						<span>첫화듣기</span>
					</button> -->
				</div>
				<div class="datail_other_wrap">
					<ul>
						<li>
							<a href="javascript:void(0);"
								><div class="datail_other_img_wrap">
									<i
										@click="toggleFavorite"
										class="bx bxs-heart heart"
										v-if="favoriteInfo === 'Y'"
									></i>
									<i @click="toggleFavorite" class="bx bx-heart heart" v-else></i>
								</div>
								<span>{{ contentInfo.fav_cnt }}</span></a
							>
						</li>
						<li>
							<a href="javascript:void(0);"
								><div class="datail_other_img_wrap">
									<i class="bx bx-message-square-dots"></i>
								</div>
								<span>{{ commentCnt }}</span></a
							>
						</li>
						<li>
							<a @click="shareLink" href="javascript:void(0);"
								><div class="datail_other_img_wrap"><i class="bx bx-share-alt"></i></div>
								<span>공유</span></a
							>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<transition>
			<div v-show="visibleClipboard" class="clipboardConsole">링크 복사 완료</div>
		</transition>
	</div>
	<!--이벤트 있는 콘텐츠만-->
	<div class="content_event_wrap" v-if="contentInfo && contentInfo.evt_yn === 'Y' && eventInfo">
		<div class="content_event_tit">
			<span class="event">Event</span><span class="date">기간 : {{ eventStore.getEventDuration }}</span>
		</div>
		<div class="content_event_cont">
			<p>
				{{ eventInfo.evt_content }}
			</p>
		</div>
	</div>
</template>

<script setup>
import { getLoginInfo, checkLogin } from '@/common-methods/checkAccount';
import {
	contentDetailInfoStore,
	contentEpisodeStore,
	contentEventStore,
	contentModalStore,
	contentPlayerStore,
} from '@/store/contentDetailStore';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

const detailStore = contentDetailInfoStore();
const episodeStore = contentEpisodeStore();
const eventStore = contentEventStore();
const modalStore = contentModalStore();
const playerStore = contentPlayerStore();
const { playerVisible } = storeToRefs(playerStore);

// props로 작품 고유값 받아옴
const props = defineProps({
	contentInfo: Object,
	commentCnt: Number,
	eventInfo: Object,
	favoriteInfo: String,
	loginState: Boolean,
});

// 좋아요 버튼 토글 식
const toggleFavorite = () => {
	// loginState = true => 로그인 한 상태일 시
	if (props.loginState) {
		detailStore.toggleFavorite(props.favoriteInfo);
	} else {
		modalStore.setModalActive(true);
		modalStore.setModalType('login');
	}
};

// 클립보드 복사 메시지 박스 출력 여부
const visibleClipboard = ref(false);

// 작품 상세정보 페이지 링크 클립보드 복사
const shareLink = () => {
	navigator.clipboard.writeText(window.location.href).then(callbackClipboard);
};

// 링크 복사 콜백함수
const callbackClipboard = () => {
	visibleClipboard.value = true;
	setTimeout(() => {
		visibleClipboard.value = false;
	}, 1500);
};

// 재생버튼 클릭 시
const activePlayer = () => {
	playerVisible.value = true;
	playerStore.setPlayList();
	// playerStore.getAllEpisodes();
};
</script>

<style scoped>
.detail_page_top_wrap {
	position: relative;
}
.clipboardConsole {
	background: orange;
	color: white;
	border-radius: 8px;
	padding: 3px;
	position: absolute;
	top: 25px;
	right: 25px;
}

.v-enter-active,
.v-leave-active {
	transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}
</style>
