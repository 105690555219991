<template>
	<div class="modal_head">
		<h2>별점 평가</h2>
		<a @click.prevent="offModal"><i class="bx bx-x"></i></a>
	</div>
	<div class="modal_txt">서비스 이용 후에 참여하실 수 있습니다.</div>
	<div class="btn_wrap_line">
		<button type="button" class="btn_basic btn_grey_bg" @click="offModal">확인</button>
		<!-- <button type="button" class="btn_basic btn_green_bg" onclick="location.href='' ">서비스 이용</button> -->
	</div>
</template>

<script setup>
import { contentModalStore } from '@/store/contentDetailStore';

const store = contentModalStore();
const offModal = () => {
	store.setModalActive(false);
	store.setModalType(null);
};
</script>

<style></style>
