<template>
	<div class="content_tit_wrap">
		<div class="shortline"></div>
		<div class="tit_line" @click="visible = !visible">
			<h2>에피소드</h2>
			<div class="content_more">
				<img v-if="visible === false" alt="more" src="@/assets/images/icon_more.png" />
				<img v-else alt="more" src="@/assets/images/menu_close.png" />
			</div>
		</div>
	</div>
	<div class="ep_list_wrap" v-if="episodeList" v-show="visible">
		<div class="summarize_wrap">
			<div class="summarize_txt">
				총 <span>{{ totalItemCount }}</span
				>개 에피소드, 전체 재생시간 <span>{{ store.getEpisodesDuration }}</span>
			</div>
			<select name="order" v-model="order" class="order_select">
				<option value="NEW">최신순</option>
				<option value="OLD">1화부터</option>
			</select>
		</div>
		<div class="ep_play_list">
			<ul>
				<li v-for="(item, index) in episodeList" :key="index">
					<div class="ep_tit">
						{{ item.epi_title }} <span>[{{ episodeDuration(item.file_duration) }}]</span>
						<span class="free_audio" v-if="item.free_yn == 'Y'">무료</span>
					</div>
					<div class="ep_btn_play">
						<a @click="activePlayer(item)" href="javascript:void(0);"
							><img alt="play_btn" src="@/assets/images/icon_play.png"
						/></a>
					</div>
				</li>
			</ul>
		</div>
	</div>
	<Pagination @changePage="changePage" :page="page" :totalPage="totalPage" v-show="visible"></Pagination>
</template>

<script setup>
import { ref, watch } from 'vue';
import { contentEpisodeStore, contentModalStore, contentPlayerStore } from '@/store/contentDetailStore';
import moment from 'moment';
import { storeToRefs } from 'pinia';
import Pagination from '@/shared-components/Pagination.vue';

const playerStore = contentPlayerStore();
const { playerVisible, playList, epiNum } = storeToRefs(playerStore);

const modalStore = contentModalStore();

const props = defineProps({
	episodeList: Object,
	loginState: Boolean,
	// page: Number,
	// totalPage: Number,
	// totalItemCount: Number,
});

const store = contentEpisodeStore();
const { page, totalPage, totalItemCount } = storeToRefs(store);
// 에피소드 시간 계산 (입력 단위 : 초)
const episodeDuration = (second) => {
	const duration = moment.duration(second, 'seconds');
	return `${duration.hours()}시간 ${duration.minutes()}분 ${duration.seconds()}초`;
};

// changePage
const changePage = (page) => {
	store.setPage(page);
};

// 정렬 순서 세팅
const order = ref('NEW');
watch(order, (newData) => {
	store.setOrder(newData);
});

// 컨텐츠 출력 여부
const visible = ref(true);

// 플레이어 활성화 전 로그인 여부 검사
const activePlayer = (item) => {
	epiNum.value = item.epi_num;
	if (props.loginState || item.epi_num == 1) {
		playerStore.setPlayList();
		playerStore.setPlayEpisodeInfo(item.epi_num, false);
		playerVisible.value = true;
	} else {
		modalStore.setModalActive(true);
		modalStore.setModalType('login');
	}
};

watch(epiNum, (newData) => {
	console.log(newData);
});
</script>

<style></style>
