<template>
	<div class="detail_content" v-if="contentInfo">
		<div class="content_tit_wrap">
			<div class="shortline"></div>
			<div class="tit_line" @click="visible = !visible">
				<h2>오디오북 소개</h2>
				<div class="content_more">
					<img v-if="visible === false" alt="more" src="@/assets/images/icon_more.png" />
					<img v-else alt="more" src="@/assets/images/menu_close.png" />
				</div>
			</div>
		</div>
		<div class="introduce_list_wrap" v-show="visible">
			<div class="introduce_list">
				<table>
					<tbody>
						<tr>
							<th width="7%">작가</th>
							<td>{{ contentInfo.exposure_author }}</td>
						</tr>
						<tr>
							<th>성우</th>
							<td>{{ contentInfo.exposure_voice_actor }}</td>
						</tr>
						<tr>
							<th>줄거리</th>
							<td>
								{{ contentInfo.story_intro }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue';

// props로 작품 고유값 받아옴
const props = defineProps({
	contentInfo: Object,
});

// 컨텐츠 출력 여부
const visible = ref(true);
</script>

<style scoped>
.text_bundle .table_wrap table tbody th,
td {
	font-size: 13px;
	/* padding: 10px; */
	/* border: 1px solid #dadada; */
}
</style>
