<template>
	<div class="modal_head">
		<h2>로그인</h2>
		<a @click.prevent="offModal"><i class="bx bx-x"></i></a>
	</div>
	<div class="modal_txt">서비스 이용을 위해 로그인을 해주세요.</div>
	<div class="btn_wrap_line">
		<button type="button" class="btn_basic btn_grey_bg" @click="offModal">취소</button>
		<button type="button" class="btn_basic btn_green_bg" @click="goLogin">로그인하기</button>
	</div>
</template>

<script setup>
import { contentModalStore } from '@/store/contentDetailStore';
import { useRouter } from 'vue-router';

const store = contentModalStore();
const router = useRouter();
const offModal = () => {
	store.setModalActive(false);
	store.setModalType(null);
};

const goLogin = () => {
	store.setModalActive(false);
	store.setModalType(null);
	router.push('/login');
};
</script>

<style></style>
