<template>
	<div>
		<div class="content_wrap">
			<div class="content_area_wrap">
				<div class="detail_content_wrap" v-if="contentVisibleState">
					<DetailTop
						:contentInfo="contentInfo"
						:commentCnt="commentStore.getCommentCnt"
						:eventInfo="eventInfo"
						:favoriteInfo="favoriteInfo"
						:loginState="loginState"
					></DetailTop>
					<div class="detail_content">
						<DetailExplain :contentInfo="contentInfo"></DetailExplain>
						<DetailEpisodes :episodeList="episodeList" :loginState="loginState"></DetailEpisodes>
					</div>
					<!-- <div class="detail_content">
						<DetailScore
							:scoreInfo="scoreInfo"
							:alreadyScoreState="alreadyScoreState"
							:idx="idx"
							:loginState="loginState"
						></DetailScore>
					</div> -->
					<div class="detail_content">
						<DetailComment
							:commentList="commentList"
							:loginState="loginState"
							:loginInfo="loginInfo"
						></DetailComment>
					</div>
				</div>
			</div>
		</div>
		<teleport to=".wrapper">
			<!-- <RealAudioPlayer></RealAudioPlayer>
		<TopPlayer v-show="playerVisible && playerPosition === 'top'"></TopPlayer>
		<BottomPlayer v-show="playerVisible && playerPosition === 'bottom'"></BottomPlayer> -->
			<div class="modal_wrap" v-if="modalActive">
				<div class="modal modal_mxc">
					<component :is="setModalComponent"></component>
				</div>
			</div>
		</teleport>
	</div>
</template>

<script setup>
import DetailTop from '@/components/content-detail/DetailTop.vue';
import DetailExplain from '@/components/content-detail/DetailExplain.vue';
import DetailEpisodes from '@/components/content-detail/DetailEpisodes.vue';
import DetailScore from '../components/content-detail/DetailScore.vue';
import DetailComment from '../components/content-detail/DetailComment.vue';
import NotLogin from '@/components/content-detail/modal/NotLogin.vue';
import NotUseContent from '@/components/content-detail/modal/NotUseContent.vue';
import InsertSuccess from '@/components/content-detail/modal/InsertSuccess.vue';
import DeleteSuccess from '@/components/content-detail/modal/DeleteSuccess.vue';
import ModifySuccess from '@/components/content-detail/modal/ModifySuccess.vue';
import {
	contentCommentStore,
	contentDetailInfoStore,
	contentEpisodeStore,
	contentEventStore,
	contentPlayerStore,
	contentScoreStore,
} from '@/store/contentDetailStore';
import { contentModalStore } from '@/store/contentDetailStore';
import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { getLoginInfo, checkLogin } from '@/common-methods/checkAccount';
import { useRouter } from 'vue-router';
// import { userInfoStore } from '@/store/UserStore';
// import moment from 'moment';

const router = useRouter();

// props로 작품 고유값 받아옴
const props = defineProps({
	idx: String,
});
console.log(props.idx + ' :: props.idx');
if (props.idx === 'undefined') {
	alert('존재하지 않는 작품입니다.');
	router.go(-1);
}

// setting detail info store
const detailStore = contentDetailInfoStore();
detailStore.getContentDetail(props.idx);
const { contentInfo, favoriteInfo, contentVisibleState } = storeToRefs(detailStore);
// 작품 상세페이지 이동 시 마다 페이지 타이틀을 작품의 타이틀로 변경
watch(contentInfo, (newData) => {
	if (newData) {
		document.title = newData.opus_title + ' - 오디오북';
	}
	// checkAdult();
});

// setting episodes store
const episodeStore = contentEpisodeStore();
episodeStore.getEpisodeList(props.idx);
const { episodeList } = storeToRefs(episodeStore);

// setting comment store
const commentStore = contentCommentStore();
commentStore.getContentComment(props.idx);
const { commentList } = storeToRefs(commentStore);

//setting score store
const scoreStore = contentScoreStore();
scoreStore.getContentScore(props.idx);
const { scoreInfo, alreadyScoreState } = storeToRefs(scoreStore);

// setting event store
const eventStore = contentEventStore();
eventStore.getContentEvent(props.idx);
const { eventInfo } = storeToRefs(eventStore);

// setting modal store
const modalStore = contentModalStore();
const { modalActive, modalType } = storeToRefs(modalStore);

// setting player store
const playerStore = contentPlayerStore();
// const { playerVisible, playerPosition } = storeToRefs(playerStore);
playerStore.setIdx(props.idx);
playerStore.getAllEpisodes();

// 로그인 체크
const loginState = ref(checkLogin());
const loginInfo = ref();

// 로그인 활성화 상태 시
if (loginState.value) {
	detailStore.checkAlreadyContentFavorite(props.idx);
	scoreStore.checkAlreadyScoreState(props.idx);
	loginInfo.value = getLoginInfo(); // 로그인 시 로컬스토리지에 저장된 유저 정보를 담아온다.
}

// 모달 컴포넌트 선택 ( Login 필요, 서비스 이용, 별점 및 댓글 등록 완료)
const setModalComponent = computed(() => {
	switch (modalType.value) {
		case 'login':
			return NotLogin;
		case 'service':
			return NotUseContent;
		case 'insert':
			return InsertSuccess;
		case 'delete':
			return DeleteSuccess;
		case 'modify':
			return ModifySuccess;
		default:
			return false;
	}
});

// 작품 리스트에서 1차로 성인여부 확인 이후 직접 주소를 치고 들어왔을 경우에도 한번 더 확인
// function checkAdult() {
// 	const userStore = userInfoStore();
// 	const { user_info_list } = storeToRefs(userStore);
// 	if (contentInfo.value.age_limit == 19) {
// 		try {
// 			// user_info_list가 null 일 경우 로그인이 풀린 상태
// 			if (!user_info_list.value.birth_date) {
// 				alert('본인인증을 먼저 진행해주세요');
// 				router.push('/');
// 			} else {
// 				const birth_date = moment(user_info_list.value.birth_date);
// 				if (moment().diff(birth_date.format('YYYYMMDD'), 'years') < 19) {
// 					alert('연령 제한이 되어있는 작품입니다.');
// 					router.push('/');
// 				}
// 			}
// 		} catch {
// 			alert('성인용 작품은 로그인이 필요합니다');
// 			router.push('/');
// 		}

// 		console.log('19금 작품');
// 	}
// }
</script>

<style></style>
